import React, { memo, useState, useEffect } from 'react';
import './MainPage.less';
import logo from '../../assets/images/logo.png';

import { Link } from 'react-router-dom';
import { Layout, notification, Modal } from 'antd';
import { useDispatch, useSelector } from 'react-redux';

import Loader from '../../common/components/Loader/Loader';
import UserDropdown from './components/Layout/UserDropdown/UserDropdown';
import LeftSidebar from './components/Layout/LeftSidebar/LeftSidebar';
import ChangePasswordModal from './components/Modals/ChangePasswordModal/ChangePasswordModal';
import TabsHolder from './components/Tabs/TabsHolder';

import useWindowSize from '../../hooks/useWindowSize';
import {
  getUserData,
  getRequisites,
  getLoans,
  getDepositTypes,
  getDepositRequisites
} from '../../redux/ducks/user';
import { sendPaymentStatus, clearPaymentServerResponse } from '../../redux/ducks/payment';
import useHistoryNavigation from '../../hooks/useHistoryNavigation';

const { Header, Content, Footer } = Layout;

const BASE_API_PATH = process.env.REACT_APP_API_URL;

const initTab = { id: 1, title: 'Заявки на займ' };

const MainPage = () => {
  const [visible, setVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [paymentLoading, setPaymentLoading] = useState(false);
  const [mainHeight, setMainHeight] = useState(0);
  const [activeTab, setActiveTab] = useState(initTab);

  const userData = useSelector((state) => state.user.data);
  const serverResponse = useSelector((state) => state.user.serverResponse);
  const paymentServerResponse = useSelector((state) => state.payment.serverResponse);

  const { height } = useWindowSize();
  const dispatch = useDispatch();

  const config = useSelector(state => state.config);
  const fileIsLoading = useSelector((state) => state.messages.fileIsLoading);

  const { goToLogin, clearUrl, history } = useHistoryNavigation()

  // При загрузке главной страницы получаем все данные юзера (Заявки на займы, Займы, Сбережения, Взносы),
  // получаем реквизиты и типы займов.
  useEffect(() => {
    setIsLoading(true);
    dispatch(getUserData());
  }, [dispatch]);

  // Если был переход на оплату и возврат после оплаты в приложение проверяем есть ли в LS вкладка,
  // с которой ушли
  useEffect(() => {
    let tab = JSON.parse(localStorage.getItem('tab'));
    if (tab) {
      setActiveTab(tab);
      localStorage.removeItem('tab');
    };
  }, []);

  // Если данные юзера получены, то мы отключаем глобальный лоудер
  useEffect(() => {
    if (userData && userData.КодОшибки === '0' && isLoading) {
      setIsLoading(false);
      // если нормально авторизировались то забираем доступные типы займой и возможные дополнительные поля-реквизиты для займов
      dispatch(getRequisites());
      dispatch(getLoans());
      dispatch(getDepositTypes());
      dispatch(getDepositRequisites());
    }
    if (serverResponse && serverResponse.status === 500) {
      setIsLoading(false);
      notification.error({
        message: 'Ошибка',
        description: 'Проблемы с сервером',
      });
    }
  }, [userData, dispatch, isLoading, serverResponse, goToLogin]);

  // Высчитываем высоту основного блока
  useEffect(() => {
    let a = document.querySelector('.main-header');
    let b = a.getBoundingClientRect();
    setMainHeight(height - b.height);
  }, [height]);

  // Если произошел редирект с оплаты, то считываем id и по апишке отправляем
  // успех у нас оплаты или неуспех (в апи учавствует флаг success или fail)
  useEffect(() => {
    let a = history.location.pathname.split('/');
    if (a[1] === 'payment') {
      // window.location.reload();
      let b = history.location.search.split('&');
      let c = b[0].split('=');
      // console.log(b, c);
      if (c[1]) {
        let vals = {
          sb_order_uuid: c[1],
        };
        // console.log(vals);
        setPaymentLoading(true);
        dispatch(sendPaymentStatus({ vals: vals, url: a[2] }));
      }
    }
  }, [history.location.pathname, history.location.search, dispatch]);

  // Если апишка нам ответила успехом, то показываем юзеру транзакция прошла успешно или произовшла ошибка.
  useEffect(() => {
    if (paymentServerResponse && paymentLoading && paymentServerResponse.status === 200) {
      setPaymentLoading(false);
      if (paymentServerResponse.url === 'success') {
        Modal.success({
          title: 'Готово!',
          content: 'Транзакция прошла успешно',
          centered: true,
          // onOk: () => {},
        });
        // window.location.reload();
      }
      if (paymentServerResponse.url === 'fail') {
        Modal.error({
          title: 'Ошибка!',
          content: 'Транзакция закончилась неудачей',
          centered: true,
          // onOk: () => {},
        });
      }
      dispatch(clearPaymentServerResponse());
      clearUrl();
    }
    // такое происходит если мы Id оплаты уже отправляли.
    // Если вдруг юзер туда-сюда-обратно в браузере кнопку тыкает history.goBack и history.goForward
    // Просто чистим ответ, ничего не показываем
    if (paymentServerResponse && paymentLoading && paymentServerResponse.status === 400) {
      setPaymentLoading(false);
      clearUrl();
      dispatch(clearPaymentServerResponse());
    }
  }, [paymentServerResponse, paymentLoading, dispatch, clearUrl]);

  return (
    <>
      <Loader isLoading={fileIsLoading}/>
      <Loader isLoading={isLoading} />
      <Layout className='main-page'>
        <Header className='main-header'>
          <nav>
            <Link to='/' onClick={() => setActiveTab(initTab)}>
              <img src={config.logo ? `${BASE_API_PATH}${config.logo}` : logo} alt='logo' />
              {config.name ? config.name : 'Микрозаймы'}
            </Link>
          </nav>
          <UserDropdown openChangeModal={() => setVisible(true)} />
        </Header>
        <Layout style={{ minHeight: `${mainHeight}px` }}>
          <LeftSidebar activeTab={activeTab} setActiveTab={setActiveTab} mainHeight={mainHeight} />
          <div className='main-content-wrapper'>
            <Content className='main-content'>
              <TabsHolder activeTab={activeTab} isLoading={isLoading} />
            </Content>
            <ChangePasswordModal
              visible={visible}
              onCancel={() => {
                setVisible(false);
              }}
            />
            <Footer>{config.footer_text ? config.footer_text : 'Личный кабинет'} Версия 1.2.501</Footer>
          </div>
        </Layout>
      </Layout>
    </>
  );
};

export default memo(MainPage);
