import React, { memo, useEffect, useCallback, useState } from 'react';
import { Modal, Form } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { useSelector, useDispatch } from 'react-redux';
import SMSFormAccept from '../../../../../common/components/Forms/SMSFormAccept/SMSFormAccept';
import AcceptForm from './AcceptForm/AcceptForm';
import { acceptMessage } from '../../../../../redux/ducks/messages';

const initDisable = { is: true, КодСМС: '' };

// Модалка подписи сообщений от администратора.
const AcceptModal = ({ visible, onCancel, flags, UIDСообщения }) => {
  const [form] = Form.useForm();
  const [isLoading, changeIsLoading] = useState(false);
  const [disabled, setDisabled] = useState({ is: false });
  const [smsWork, setSmsWork] = useState(false);

  const msgWork = useSelector((state) => state.messages.msgWork);
  const dispatch = useDispatch();

  // Если есть требование подписи через СМС, то мы меняем флаг disabled
  // и пока мы не подтвердим смс не будет возможности нажать кнопку Подписать
  useEffect(() => {
    if (flags && flags.ПодписьЧерезСМС) {
      setDisabled(initDisable);
    }
  }, [flags]);

  const onCreate = (values) => {
    let obj = {
      UIDСообщения,
      Подписано: true,
    };
    if (disabled.КодСМС) {
      obj.КодСМС = disabled.КодСМС;
    }
    if (flags.ПодписьЧерезСелфиКод || flags.ПодписьЧерезСелфиПаспорт) {
      obj.photo = values.upload[0].originFileObj
      // obj.Скан = {};
      // obj.Скан = JSON.parse(localStorage.getItem('files'))[0];
      // if (disabled.КодСМС) {
      //   obj.Скан = { ...obj.Скан, КодСМС: disabled.КодСМС };
      // }
    }
    // console.log(obj);
    changeIsLoading(true);
    dispatch(acceptMessage(obj));
  };

  // Чистим модалку при закрытии или успехе.
  const clearModal = useCallback(() => {
    onCancel();
    setDisabled(initDisable);
    setSmsWork(false);
    changeIsLoading(false);
    form.resetFields();
  }, [form, onCancel]);

  // Если Сообщение подписано, то автоматом закрываем модалку
  // и вылазит сообщение свехру 'Сообщение успешно подписано' Тут только закрытие модалки
  useEffect(() => {
    if (msgWork && msgWork.Подписан && isLoading) {
      clearModal();
    }
    if (msgWork && msgWork.status === 'error' && isLoading) {
      changeIsLoading(false);
    }
  }, [msgWork, isLoading, clearModal]);

  // Если в модалке были внесены какие-то изменения после открытия,
  // то спрашиваем правда ли хотим закрыть модалку.
  const closeConfirm = () => {
    let values = form.getFieldsValue();
    if (
      JSON.stringify(values) === '{}' &&
      ((disabled.is && disabled.КодСМС === '') || !disabled.is) &&
      !smsWork
    ) {
      onCancel();
    } else {
      Modal.confirm({
        // title: 'Confirm',
        icon: <ExclamationCircleOutlined />,
        content:
          'Вы действительно хотите прервать процесс подписи? Введенные данные не сохранятся.',
        okText: 'Да',
        cancelText: 'Нет',
        centered: true,
        onOk: () => {
          onCancel();
          setTimeout(() => {
            clearModal();
          }, 100);
        },
      });
    }
  };

  return (
    <Modal
      visible={visible}
      title='Подпись'
      okText='Подтвердить подпись'
      cancelText='Выйти'
      onCancel={closeConfirm}
      onOk={() => {
        form
          .validateFields()
          .then((values) => {
            onCreate(values);
          })
          .catch((info) => {
            console.log('Validate Failed:', info);
          });
      }}
      className='change-password-modal'
      centered
      confirmLoading={isLoading}
      okButtonProps={{ disabled: disabled.is }}
    >
      {/* Если требуется подпись через смс, то модалка смс подгружается. Если селфи+паспорт, то ее нет. */}
      {flags && flags.ПодписьЧерезСМС && (
        <SMSFormAccept
          setDisabled={setDisabled}
          disabled={disabled}
          setSmsWork={setSmsWork}
          smsWork={smsWork}
          visible={visible}
          type='subscribe'
        />
      )}
      <AcceptForm
        form={form}
        isLoading={isLoading}
        flags={flags}
        setDisabled={setDisabled}
        // visible={visible}
      />
    </Modal>
  );
};

export default memo(AcceptModal);
