import React, { memo, useState, useEffect } from 'react';
import './Tabs.less';
import { Button } from 'antd';
import { useSelector } from 'react-redux';

import LoanApplications from './LoanApplications/LoanApplications';
import Loans from './Loans/Loans';
import SavingApplication from './SavingApplications/SavingApplications';
import Savings from './Savings/Savings';
import StandardContributions from './StandardContributions/StandardContributions';
import RecurringContributions from './RecurringContributions/RecurringContributions';
import Messages from './Messages/Messages';
import Contacts from './Contacts/Contacts';
import VotePage from './VotePage/VotePage';
import LoanModal from '../Modals/LoanModal/LoanModal';
import DepositModalNew from '../Modals/DepositModal_new/DepositModal_new'
import DepositModal from '../Modals/DepositModal/DepositModal'
import MsgModal from '../Modals/MsgModal/MsgModal';

const initVisible = { loan: false, msg: false, deposit: false };

const TabsHolder = ({ activeTab, isLoading }) => {

  const [visible, setVisible] = useState(initVisible);
  const [initMsg, setInitMsg] = useState({});
  const [newMsg, setNewMsg] = useState(false);
  const config = useSelector(state => state.config);
  const depositSetting = config['1c_configs'].return
  .НастройкиСбережений.ПодписыватьЗаявки;

  useEffect(() => {
    if (initMsg.UIDСообщения) {
      setVisible(visible => { return { ...visible, msg: true } })
    };
  }, [initMsg]);

  return (
    <div className='site-layout-background'>
      {/* {isLoading && <Loader isLoading={isLoading}/>} */}
      <div className='tabs-header'>
        <h1>{activeTab.title}</h1>
        {activeTab.id === 1 && <Button type='primary' onClick={() => setVisible({ ...visible, loan: true })}>Подать заявку</Button>}
        {activeTab.id === 3 && <Button type='primary' onClick={() => setVisible({ ...visible, deposit: true })}>Подать заявку</Button>}
        {activeTab.id === 7 && <Button type='primary' onClick={() => setVisible({ ...visible, msg: true })}>Написать сообщение</Button>}
      </div>
      <div className='tabs-main'>
        {activeTab.id === 1 && <LoanApplications isLoading={isLoading} />}
        {activeTab.id === 2 && <Loans />}
        {activeTab.id === 3 && <SavingApplication />}
        {activeTab.id === 4 && <Savings />}
        {activeTab.id === 5 && <StandardContributions />}
        {activeTab.id === 6 && <RecurringContributions />}
        {activeTab.id === 7 && <Messages setInitMsg={setInitMsg} newMsg={newMsg} setNewMsg={setNewMsg} />}
        {activeTab.id === 8 && <Contacts />}
        {activeTab.id === 9 && <VotePage />}
      </div>
      <LoanModal
        visible={visible.loan}
        onCancel={() => {
          setVisible({ ...visible, loan: false });
        }}
      />
      {depositSetting ?
        <DepositModalNew
          visible={visible.deposit}
          onCancel={() => {
            setVisible({ ...visible, deposit: false });
          }}
        />
      :
        <DepositModal
          visible={visible.deposit}
          onCancel={() => {
            setVisible({ ...visible, deposit: false });
          }}
        />
      }

      <MsgModal
        visible={visible.msg}
        onCancel={() => {
          setVisible({ ...visible, msg: false });
          setInitMsg({});
        }}
        initMsg={initMsg}
        clearInitMsg={() => setInitMsg({})}
        setNewMsg={setNewMsg}
      />
    </div>
  );
};

export default memo(TabsHolder);
