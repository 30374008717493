import React, { memo, useEffect, useCallback, useState } from 'react';
import { Modal, message} from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { useSelector, useDispatch } from 'react-redux';
import SMSFormAccept from '../../Forms/SMSFormAccept/SMSFormAccept';

const initDisable = { is: true, КодСМС: '' };

const VoteAcceptSmsModal = ({ visible, onCancel, onSuccessSmsAccept, initClean, okText }) => {
  const [isLoading, changeIsLoading] = useState(false);
  const [disabled, setDisabled] = useState(initDisable);
  const [smsWork, setSmsWork] = useState(false);
  const [loanDisabled, setLoanDisabled] = useState(true);

  const msgWork = useSelector((state) => state.messages.msgWork);
  const dispatch = useDispatch();

  const smsServerResponse = useSelector((state) => state.sms.serverResponse)

  // Подпись сообщения
  const onCreate = () => {
    disabled.КодСМС && onSuccessSmsAccept(disabled.КодСМС)
  };

  // Чистим модалку при закрытии или успехе.
  const clearModal = useCallback(() => {
    onCancel();
    setDisabled(initDisable);
    setSmsWork(false);
    setLoanDisabled(true);
    changeIsLoading(false);
  }, [onCancel]);

  // Если модалка закрывается - инициируем чистку форм
  useEffect(() => {
    if(initClean.initClean){
      clearModal();
      initClean.setInitClean();
    };
  }, [initClean, clearModal])

  // Если Сообщение подписано, то форма подписи становится дизейбл,
  // а кнопка отправки заявки на займ активной
  useEffect(() => {
    if (msgWork && msgWork.Подписан && isLoading) {
      setLoanDisabled(false)
      changeIsLoading(false);
      setDisabled((d) => { return { ...d, is: true }} );
      message.info('Подпись подтверждена');
    };
    if (msgWork && msgWork.status === 'error' && isLoading) {
      changeIsLoading(false);
    };
  }, [msgWork, isLoading, clearModal]);

  // Если в модалке были внесены какие-то изменения после открытия,
  // то спрашиваем правда ли хотим закрыть модалку.
  const closeConfirm = () => {
    if (
      ((disabled.is && disabled.КодСМС === '') || !disabled.is) &&
      !smsWork
    ) {
      onCancel();
    } else {
      Modal.confirm({
        icon: <ExclamationCircleOutlined />,
        content:
          'Вы действительно хотите прервать процесс подписи? Введенные данные не сохранятся.',
        okText: 'Да',
        cancelText: 'Нет',
        centered: true,
        onOk: () => {
          onCancel();
          setTimeout(() => {
            clearModal();
          }, 100);
        },
      });
    }
  };

  useEffect(() => {
    if(isLoading && smsServerResponse.status === 'succes') {
      setDisabled({...disabled, is: false});
    };
  },[disabled, dispatch, isLoading, smsServerResponse]);

  return (
    <Modal
      visible={visible}
      title='Подпись'
      okText={okText}
      cancelText='Выйти'
      onCancel={closeConfirm}
      onOk={onCreate}
      className='change-password-modal'
      centered
      confirmLoading={isLoading}
      okButtonProps={{ disabled: disabled.is }}
    >
      <SMSFormAccept
        setDisabled={setDisabled}
        disabled={!loanDisabled ? loanDisabled : disabled}
        setSmsWork={setSmsWork}
        smsWork={smsWork}
        visible={visible}
        initClean={initClean}
        skipSmsTimeout
        type='request'
        divider={false}
      />
    </Modal>
  );
};

export default memo(VoteAcceptSmsModal);
