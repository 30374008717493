const BASE_API_URL = process.env.REACT_APP_URL;
console.log(process.env.REACT_APP_URL);
console.log(BASE_API_URL);
const getDefaultHeader = () => {
  if (BASE_API_URL) {
    return {
      headers: {
        'Content-type': 'application/json',
        'x-LkHost': BASE_API_URL,
      },
    };
  } else {
    return {
      headers: {
        'Content-type': 'application/json',
      },
    };
  }
};

export default getDefaultHeader;
