import React, { memo, useEffect, useCallback, useState } from 'react';
import { Modal, Form } from 'antd';
import { useSelector, useDispatch } from 'react-redux';

import ChangePasswordForm from './ChangePasswordForm/ChangePasswordForm';

import { clearUserServerResponse, changePassword } from '../../../../../redux/ducks/user';

const ChangePasswordModal = ({ visible, onCancel }) => {
  const [form] = Form.useForm();

  const [ isLoading, changeIsLoading ] = useState(false);
  const serverResponse = useSelector((state) => state.user.serverResponse);
  const dispatch = useDispatch();

  const onCreate = (values) => {
    changeIsLoading(true);
    dispatch(changePassword(values));
  };

  const changePasswordErrorConfirm = useCallback(() => {
    Modal.error({
      title: 'Ошибка!',
      content: "Не удалось изменить пароль",
      centered: true,
    });
  }, []);

  const changePasswordSuccessConfirm = useCallback(() => {
    Modal.success({
      title: 'Успех',
      content: 'Вы успешно изменили пароль',
      centered: true,
      onOk: () => {
        onCancel();
        setTimeout(() => form.resetFields(), 100);
      },
    });
  }, [form, onCancel]);

  useEffect(() => {
    if (serverResponse && serverResponse.status === 200 && isLoading) {
      if (serverResponse.data.return.КодОшибки === '0') {
        changePasswordSuccessConfirm();
      } else {
        changePasswordErrorConfirm(serverResponse.data.return.ОписаниеОшибки);
      };
      dispatch(clearUserServerResponse());
    };
    if (serverResponse && serverResponse.status === 'error' && isLoading) {
      changeIsLoading(false);
      dispatch(clearUserServerResponse());
    };
    // if (serverResponse && serverResponse.status === 401 && isLoading) {
    //   changeIsLoading(false);
    //   dispatch(clearUserServerResponse());
    //   notification.error({
    //     message: 'Ошибка',
    //     description: 'Проблемы с сервером',
    //   });
    // }
  }, [serverResponse, changePasswordSuccessConfirm, changePasswordErrorConfirm, dispatch, isLoading]);

  return (
    <Modal
      visible={visible}
      title='Изменение пароля'
      okText='Изменить пароль'
      cancelText='Выйти'
      onCancel={() => {
        onCancel();
        form.resetFields();
      }}
      onOk={() => {
        form
          .validateFields()
          .then((values) => {
            onCreate(values);
          })
          .catch((info) => {
            console.log('Validate Failed:', info);
          });
      }}
      className='change-password-modal'
      centered
      confirmLoading={isLoading}
    >
      <ChangePasswordForm form={form} isLoading={isLoading}/>
    </Modal>
  );
};

export default memo(ChangePasswordModal);
