import React, { memo, useState, useEffect } from 'react';
import { Row, Col } from 'antd';
import { useSelector } from 'react-redux';
import BtnsBlock from './BtnsBlock';
import DataBlock from './DataBlock';
import PaymentModal from '../../Modals/PaymentModal/PaymentModal';
import EarlyPaymentModal from '../../Modals/EarlyPaymentModal/EarlyPaymentModal';
import QRPaymentModal from '../../Modals/QRPaymentModal/QRPaymentModal';

const OpenCard = ({ record, setVisible, colNum }) => {
  const [payment, setPayment] = useState(false);
  const [early, setEarly] = useState(false);
  const [qr, setQr] = useState(false);
  const [initSum, setInitSum] = useState('0.00');
  const config = useSelector(state => state.config);

  const loansSettings = config['1c_configs'].return.НастройкиЗаймов;

  useEffect(() => {
    let a =
      +record.тПлатеж.Номинал +
      +record.тПлатеж.Комиссия +
      +record.тПлатеж.Пени +
      +record.тПлатеж.ПениКомиссия +
      +record.тПлатеж.Проценты;
    setInitSum(a);
  }, [record]);

  const getBtnsBlock = () => {
    return (
      <BtnsBlock
        settings={loansSettings}
        setVisible={setVisible}
        uid={record.UIDДоговора}
        openPayment={setPayment}
        openEarly={() => setEarly(true)}
        openQR={setQr}
      />
    );
  };

  console.log(qr)
  return (
    <>
      <div className='more-info-wrapper'>
        {colNum === 24 ? (
          // mobile
          <>
            <Row>
              <Col span={colNum}>{getBtnsBlock()}</Col>
            </Row>

            <Row>
              <Col span={colNum}>
                <DataBlock record={record} />
              </Col>
            </Row>
          </>
        ) : (
          // desctop
          <>
            <Row>
              <Col span={colNum}>
                <DataBlock record={record} />
              </Col>
              <Col span={colNum}>{getBtnsBlock()}</Col>
            </Row>
          </>
        )}
      </div>
      <PaymentModal
        visible={payment}
        onCancel={() => setPayment(false)}
        initSum={initSum}
        type='ПлатежПоДоговоруЗайма'
        uid={record.UIDДоговора}
        docNum={record.НомерДоговора}
        loanNum={record.Займ && record.Займ.UIDЗайма}
        title={
          payment === 'sber'
            ? 'Оплата Сбер'
            : payment === 'payler'
            ? 'Оплата Payler'
            : payment === 'opening'
            ? 'Оплата Банк Открытие'
            : payment === 'alfa'
            ? 'Оплата Альфа Банк'
            : 'Оплата'
        }
        btnText='Перейти к оплате'
        tab={{ id: 2, title: 'Займы' }}
        тПлатеж={record.тПлатеж}
        ДатаДоговора={record.ДатаДоговора}
      />
      <EarlyPaymentModal
        visible={early}
        onCancel={() => setEarly(false)}
        initSum={initSum}
        type='ПлатежПоДоговоруЗайма'
        uid={record.UIDДоговора}
        docNum={record.НомерДоговора}
        title='Заявление на досрочное погашение'
        btnText='Да'
        tab={{ id: 2, title: 'Займы' }}
      />
      <QRPaymentModal
        visible={qr}
        onCancel={() => setQr(false)}
        title={qr === 'simple' ? 'Оплатить QR-кодом' : 'Оплатить QR-кодом Сбер'}
        initVals={{
          UIDДоговора: record.UIDДоговора,
          ВидДоговора: 'ДоговорЗайма',
          Сумма: initSum,
          ОтчетКодСбер: qr === 'sber' ? true : false,
        }}
      />
    </>
  );
};

export default memo(OpenCard);
