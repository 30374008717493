import React, { memo } from 'react';
import { Modal, Spin } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { clearFile } from '../../../../../redux/ducks/user';
import ReactHtmlParser from 'react-html-parser';

// Модалка для отрисовки карточки займа и тд. При закрытии модалки чистим файл.
const FileModal = ({ visible, onClose }) => {
  const file = useSelector((state) => state.user.credit.file);
  const dispatch = useDispatch();
  return (
    <Modal
      visible={visible.is}
      title={visible.title}
      onCancel={() => {
        onClose();
        dispatch(clearFile());
      }}
      className='info-loan-modal'
      centered
    >
      <div className='file-wrapper'>
        {file ? (
          ReactHtmlParser(file)
        ) : (
          <div className='file-loader'>
            <Spin size='large' />
          </div>
        )}
      </div>
    </Modal>
  );
};

export default memo(FileModal);
